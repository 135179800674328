import { Fragment, useEffect, useState } from "react";
import HeaderSection from "../header/header.component";
import PageHeaderSection from "../header-section/header-section";
import { Col, Form, Row } from "react-bootstrap";
import { netural400 } from "../../constants/color";
import CustomLabel from "../shared/label/custom-label.component";
import CustomButton from "../button/button.component";
import {
  FETCH_BUSINESS_VERIFICATION_INFO,
  HANDLE_BUSINESS_VERIFICATION_REQUEST,
} from "../../services/graphql/businessAccount";
import { useLazyQuery, useMutation } from "@apollo/client";
import NoticeBanner from "../notice-banner/notice-banner";
import {
  business_approved,
  business_pending,
  business_processing,
  business_rejected,
} from "../../constants/constant";
import LoadingSpinner from "../shared/loading-spinner/loading-spinner.component";
import { toast } from "react-toastify";

const BusinessInfoForm = () => {
  const [validated, setValidated] = useState(false);
  const [errorFields, setErrorFields] = useState({});
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [reverificationInfo, setReverificationInfo] = useState({
    legalBusinessName: "",
    businessNumber: "",
    taxNumber: "",
    taxRate: "",
    location: "Florida - US",
    termsAndConditions: "",
    privacyPolicy: "",
  });
  const [rejectionReason, setRejectionReason] = useState("");
  const [verificationStatus, setVerificationStatus] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "region") {
      setReverificationInfo({
        ...reverificationInfo,
        location: value,
      });
    } else {
      // For taxRate, ensure it is numeric and <= 100
      if (name === "taxRate") {
        if (!/^\d*\.?\d*$/.test(value) || parseFloat(value) > 100) return;

        setReverificationInfo({
          ...reverificationInfo,
          [name]: value === "" ? "" : parseFloat(value) || 0, // Handle empty string & allow 0
        });
      } else {
        setReverificationInfo({
          ...reverificationInfo,
          [name]: value,
        });
      }

      setErrorFields({
        ...errorFields,
        [name]: "",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let hasError = false;
    let errors = {};

    if (
      !reverificationInfo.legalBusinessName ||
      reverificationInfo.legalBusinessName.trim() === ""
    ) {
      errors.legalBusinessName = "Legal business name is required.";
      hasError = true;
    }
    if (
      !reverificationInfo.businessNumber ||
      reverificationInfo.businessNumber.trim().length < 9
    ) {
      errors.businessNumber = "Business number must be at least 9 characters.";
      hasError = true;
    }
    if (
      !reverificationInfo.taxNumber ||
      reverificationInfo.taxNumber.trim().length < 15
    ) {
      errors.taxNumber = "Tax number must be at least 15 characters.";
      hasError = true;
    }
    if (
      reverificationInfo.taxRate === "" || // Prevent empty string
      reverificationInfo.taxRate === null || // Prevent null
      reverificationInfo.taxRate === undefined || // Prevent undefined
      isNaN(reverificationInfo.taxRate) || // Prevent NaN
      reverificationInfo.taxRate < 0 ||
      reverificationInfo.taxRate > 100
    ) {
      errors.taxRate = "Tax rate must be a number between 0 and 100.";
      hasError = true;
    }
    if (
      !reverificationInfo.termsAndConditions ||
      reverificationInfo.termsAndConditions.trim().length < 100
    ) {
      errors.termsAndConditions =
        "Terms and Conditions must be at least 100 characters.";
      hasError = true;
    }
    if (
      !reverificationInfo.privacyPolicy ||
      reverificationInfo.privacyPolicy.trim().length < 100
    ) {
      errors.privacyPolicy = "Privacy Policy must be at least 100 characters.";
      hasError = true;
    }

    setErrorFields(errors);

    if (!hasError) {
      setValidated(true);
      // console.log("Form Submitted:", reverificationInfo);
      await handleBusinessVerificationRequest({
        variables: {
          verificationInfo: reverificationInfo,
          verificationType: verificationStatus,
        },
      });
    }
  };

  useEffect(() => {
    getBusinessVerificationInfo("userId");
  }, []);

  const getBusinessVerificationInfo = async (id) => {
    await fetchBusinessVerification({
      variables: {
        business_id: id,
      },
    });
  };

  const [fetchBusinessVerification, { loading: loadingBusinessVerification }] =
    useLazyQuery(FETCH_BUSINESS_VERIFICATION_INFO, {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data && data.fetchBusinessVerification) {
          let verificationInfo = data.fetchBusinessVerification;
          // console.log("All value----------------", verificationInfo);
          setVerificationStatus(verificationInfo.verification);
          setRejectionReason(verificationInfo.rejectionReason);
          setReverificationInfo({
            legalBusinessName: verificationInfo.legalBusinessName
              ? verificationInfo.legalBusinessName
              : "",
            businessNumber: verificationInfo.businessNumber
              ? verificationInfo.businessNumber
              : "",
            taxNumber: verificationInfo.taxNumber
              ? verificationInfo.taxNumber
              : "",
            taxRate: verificationInfo.taxRate
              ? parseFloat(verificationInfo.taxRate)
              : "",
            location: verificationInfo.location
              ? verificationInfo.location
              : "",
            termsAndConditions: verificationInfo.termsAndConditions
              ? verificationInfo.termsAndConditions
              : "",
            privacyPolicy: verificationInfo.privacyPolicy
              ? verificationInfo.privacyPolicy
              : "",
          });
          setIsFormDisabled(
            verificationInfo.verification === "Processing" ||
              verificationInfo.verification === "Reverifying"
              ? true
              : false
          );
        }
      },
    });

  const [
    handleBusinessVerificationRequest,
    { loading: loadingBusinessVerificationRequest },
  ] = useMutation(HANDLE_BUSINESS_VERIFICATION_REQUEST, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data && data.handleBusinessVerificationRequest) {
        const business_response = data.handleBusinessVerificationRequest;
        if (business_response) {
          toast.success(business_response.message, {
            style: { width: "450px" },
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored ",
          });
          getBusinessVerificationInfo("userId");
        } else {
          toast.error(business_response.message, {
            style: { width: "450px" },
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored ",
          });
        }
      }
    },
  });

  return (
    <div
      className="main-div-right-section"
      style={{ backgroundColor: "white" }}
    >
      <Fragment>
        <HeaderSection currentTab={"businessInfo"} />
        <div className="second-section-style">
          <NoticeBanner
            title={
              verificationStatus === "Approved"
                ? business_approved
                : verificationStatus === "Pending"
                ? business_pending
                : verificationStatus === "Processing" ||
                  verificationStatus === "Reverifying"
                ? business_processing
                : verificationStatus === "Rejected"
                ? business_rejected + rejectionReason
                : null
            }
          />
          <PageHeaderSection text="Business info" />
          {loadingBusinessVerification || loadingBusinessVerificationRequest ? (
            <LoadingSpinner size="medium" overlay={true} />
          ) : (
            <Form
              validated={validated}
              onSubmit={handleSubmit}
              style={{ marginTop: "2%" }}
            >
              <Row className="mb-3">
                <Col
                  xl={12}
                  lg={12}
                  md={12}
                  style={{ marginBottom: "3%", paddingLeft: 0 }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {/* Legal Business Name */}
                    <Col md="5" lg="5">
                      <Form.Group
                        controlId="validationCustom01"
                        style={{ color: netural400 }}
                      >
                        <Form.Label>Legal business name</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Legal business name"
                          name="legalBusinessName"
                          value={reverificationInfo.legalBusinessName}
                          maxLength={50}
                          onChange={handleInputChange}
                          disabled={isFormDisabled}
                          isInvalid={!!errorFields.legalBusinessName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errorFields.legalBusinessName}
                        </Form.Control.Feedback>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "0.5%",
                          }}
                        >
                          <CustomLabel
                            style={{ color: netural400, fontSize: 12 }}
                          >
                            Max. 50 characters
                          </CustomLabel>
                          {reverificationInfo.legalBusinessName.length < 50 ? (
                            <CustomLabel
                              style={{ color: netural400, fontSize: 12 }}
                            >
                              {reverificationInfo.legalBusinessName.length}/50
                            </CustomLabel>
                          ) : (
                            <CustomLabel
                              style={{
                                color: "rgba(242, 38, 19, 0.9)",
                                fontSize: 12,
                              }}
                            >
                              Max length reached -{" "}
                              {reverificationInfo.legalBusinessName.length}/50
                            </CustomLabel>
                          )}
                        </div>
                      </Form.Group>
                    </Col>

                    {/* Business Number */}
                    <Col md="5" lg="5">
                      <Form.Group
                        controlId="validationCustom01"
                        style={{ color: netural400 }}
                      >
                        <Form.Label>Business number</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Business number"
                          name="businessNumber"
                          value={reverificationInfo.businessNumber}
                          minLength={9}
                          maxLength={50}
                          onChange={handleInputChange}
                          disabled={isFormDisabled}
                          isInvalid={!!errorFields.businessNumber}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errorFields.businessNumber}
                        </Form.Control.Feedback>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "0.5%",
                          }}
                        >
                          <CustomLabel
                            style={{ color: netural400, fontSize: 12 }}
                          >
                            Min. 9 characters
                          </CustomLabel>
                          {reverificationInfo.businessNumber.length < 50 ? (
                            <CustomLabel
                              style={{ color: netural400, fontSize: 12 }}
                            >
                              {reverificationInfo.businessNumber.length}/50
                            </CustomLabel>
                          ) : (
                            <CustomLabel
                              style={{
                                color: "rgba(242, 38, 19, 0.9)",
                                fontSize: 12,
                              }}
                            >
                              Max length reached -{" "}
                              {reverificationInfo.businessNumber.length}/50 {""}
                            </CustomLabel>
                          )}
                        </div>
                      </Form.Group>
                    </Col>
                  </div>

                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                    className="mt-3"
                  >
                    {/* Tax Number */}
                    <Col md="5" lg="5">
                      <Form.Group
                        controlId="validationCustom01"
                        style={{ color: netural400 }}
                      >
                        <Form.Label>Tax number</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Tax number"
                          name="taxNumber"
                          value={reverificationInfo.taxNumber}
                          minLength={15}
                          maxLength={50}
                          onChange={handleInputChange}
                          disabled={isFormDisabled}
                          isInvalid={!!errorFields.taxNumber}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errorFields.taxNumber}
                        </Form.Control.Feedback>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "0.5%",
                          }}
                        >
                          <CustomLabel
                            style={{ color: netural400, fontSize: 12 }}
                          >
                            Min. 15 characters
                          </CustomLabel>
                          {reverificationInfo.taxNumber.length < 50 ? (
                            <CustomLabel
                              style={{ color: netural400, fontSize: 12 }}
                            >
                              {reverificationInfo.taxNumber.length}/50
                            </CustomLabel>
                          ) : (
                            <CustomLabel
                              style={{
                                color: "rgba(242, 38, 19, 0.9)",
                                fontSize: 12,
                              }}
                            >
                              Max length reached -{" "}
                              {reverificationInfo.taxNumber.length}/50 {""}
                            </CustomLabel>
                          )}
                        </div>
                      </Form.Group>
                    </Col>

                    {/* Tax Rate */}
                    <Col md="5" lg="5">
                      <Form.Group
                        controlId="validationCustom01"
                        style={{ color: netural400 }}
                      >
                        <Form.Label>Tax rate</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          inputMode="numeric"
                          placeholder="Tax rate"
                          name="taxRate"
                          value={reverificationInfo.taxRate}
                          minLength={15}
                          maxLength={50}
                          onChange={handleInputChange}
                          disabled={isFormDisabled}
                          isInvalid={!!errorFields.taxRate}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errorFields.taxRate}
                        </Form.Control.Feedback>
                        <div style={{ textAlign: "left", marginTop: "0.5%" }}>
                          <CustomLabel
                            style={{ color: netural400, fontSize: 12 }}
                          >
                            Enter tax percentage, e.g.: 13%
                          </CustomLabel>
                        </div>
                      </Form.Group>
                    </Col>
                  </div>

                  {/* Terms and Conditions */}
                  <Col md="12" lg="12" className="mt-3">
                    <Form.Group
                      controlId="validationCustom01"
                      style={{ color: netural400 }}
                    >
                      <Form.Label>Terms and Conditions *</Form.Label>
                      <Form.Control
                        required
                        as="textarea"
                        rows={3}
                        placeholder="Enter your terms and conditions here.."
                        name="termsAndConditions"
                        value={reverificationInfo.termsAndConditions}
                        minLength={100}
                        maxLength={10000}
                        onChange={handleInputChange}
                        disabled={isFormDisabled}
                        isInvalid={!!errorFields.termsAndConditions}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorFields.termsAndConditions}
                      </Form.Control.Feedback>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: "0.5%",
                        }}
                      >
                        <CustomLabel
                          style={{
                            color: netural400,
                            fontSize: 12,
                            marginRight: "1.5%",
                          }}
                        >
                          Min. 100 characters
                        </CustomLabel>
                        {reverificationInfo.termsAndConditions.length <
                        10000 ? (
                          <CustomLabel
                            style={{ color: netural400, fontSize: 12 }}
                          >
                            {reverificationInfo.termsAndConditions.length}/10000
                          </CustomLabel>
                        ) : (
                          <CustomLabel
                            style={{
                              color: "rgba(242, 38, 19, 0.9)",
                              fontSize: 12,
                            }}
                          >
                            Max length reached -{" "}
                            {reverificationInfo.termsAndConditions.length}/10000
                          </CustomLabel>
                        )}
                      </div>
                    </Form.Group>
                  </Col>

                  {/* Privacy Policy */}
                  <Col md="12" lg="12" className="mt-3">
                    <Form.Group
                      controlId="validationCustom01"
                      style={{ color: netural400 }}
                    >
                      <Form.Label>Privacy Policy</Form.Label>
                      <Form.Control
                        required
                        as="textarea"
                        rows={3}
                        placeholder="Enter your privacy policy here.."
                        name="privacyPolicy"
                        value={reverificationInfo.privacyPolicy}
                        minLength={100}
                        maxLength={10000}
                        onChange={handleInputChange}
                        disabled={isFormDisabled}
                        isInvalid={!!errorFields.privacyPolicy}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorFields.privacyPolicy}
                      </Form.Control.Feedback>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: "0.5%",
                        }}
                      >
                        <CustomLabel
                          style={{
                            color: netural400,
                            fontSize: 12,
                            marginRight: "1.5%",
                          }}
                        >
                          Min. 100 characters
                        </CustomLabel>
                        {reverificationInfo.privacyPolicy.length < 10000 ? (
                          <CustomLabel
                            style={{ color: netural400, fontSize: 12 }}
                          >
                            {reverificationInfo.privacyPolicy.length}/10000
                          </CustomLabel>
                        ) : (
                          <CustomLabel
                            style={{
                              color: "rgba(242, 38, 19, 0.9)",
                              fontSize: 12,
                            }}
                          >
                            Max length reached -{" "}
                            {reverificationInfo.privacyPolicy.length}/10000
                          </CustomLabel>
                        )}
                      </div>
                    </Form.Group>
                  </Col>

                  {/* Location */}
                  <Col md="5" lg="5" className="mt-3">
                    <Form.Group
                      controlId="radioButtonGroup"
                      style={{ color: netural400 }}
                    >
                      <Form.Label>Where are you located?</Form.Label>
                      <div style={{ display: "flex" }}>
                        <Form.Check
                          type="radio"
                          name="region"
                          id="florida-us"
                          label="Florida - US"
                          value="Florida - US"
                          checked={
                            reverificationInfo.location === "Florida - US"
                          }
                          onChange={handleInputChange}
                          disabled={isFormDisabled}
                        />
                        <Form.Check
                          type="radio"
                          name="region"
                          id="on-canada"
                          label="ON - Canada"
                          value="ON - Canada"
                          checked={
                            reverificationInfo.location === "ON - Canada"
                          }
                          onChange={handleInputChange}
                          disabled={isFormDisabled}
                          style={{ marginLeft: "5%" }}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Col>
                <Col md="5" lg="5" style={{ paddingLeft: 0 }}>
                  <CustomButton
                    title={"Submit"}
                    buttonType={isFormDisabled ? "validation" : "default"}
                    isValid={!isFormDisabled}
                    handleButtonClick={handleSubmit}
                  />
                </Col>
              </Row>
            </Form>
          )}
        </div>
      </Fragment>
    </div>
  );
};

export default BusinessInfoForm;
