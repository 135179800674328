import { Col, Form } from "react-bootstrap";
import { netural400 } from "../../constants/color";
import { Fragment } from "react";
import { QUANTITY_RESTRICTION_DROPDOWN_OPTIONS } from "../../constants/constant";

const ProductCombinationCard = ({
  variantCombination,
  index,
  updateValue,
  isEdit = false,
}) => {
  const { combination, price, discount, quantity, quantityLimit, sku } =
    variantCombination;
  return (
    <div style={{ display: "flex", width: "100%", marginBottom: "2%" }}>
      <Col
        lg={2}
        md={2}
        sm={2}
        xs={2}
        style={{ display: "flex", alignItems: "center" }}
      >
        <label
          style={{
            fontSize: "16px",
            color: netural400,
          }}
        >
          {combination}
        </label>
      </Col>
      <Col lg={2} md={2} sm={2} xs={2}>
        <Fragment>
          <Form.Group
            controlId="validationCustom01"
            style={{ color: "#A6A6A6", width: "80%" }}
          >
            <Form.Control
              required
              type="text"
              placeholder="Price"
              value={price}
              onChange={(event) => {
                const newValue = event.target.value.trim();
                // Check if the input is empty and handle backspace/delete key
                if (newValue === '' || newValue === '.') {
                  updateValue(index, "price", '');
                } else if (/^[0-9]+(\.[0-9]*)?$/.test(newValue)) {
                  updateValue(index, "price", newValue);
                }else{
                  updateValue(index, "price", price);
                }
              }}
              minLength={4}
              maxLength={7}
              step={"0.01"}
              style={{
                fontSize: "16px",
                color: netural400,
              }}
            />
          </Form.Group>
        </Fragment>
      </Col>
      <Col lg={2} md={2} sm={2} xs={2}>
        <Fragment>
          <Form.Group
            controlId="validationCustom01"
            style={{ color: "#A6A6A6", width: "80%" }}
          >
            <Form.Control
              required
              type="text"
              inputMode="numeric"
              placeholder="Discount"
              value={discount}
              onChange={(event) => {
                const newValue = event.target.value.trim();
                // Check if the input is empty and handle backspace/delete key
                if (newValue === '' || newValue === '.') {
                  updateValue(index, "discount", '');
                } else if (/^[0-9]+(\.[0-9]*)?$/.test(newValue) && parseFloat(newValue) >= 0 && parseFloat(newValue) <= 100) {
                  updateValue(index, "discount", newValue);
                }else{
                  updateValue(index, "discount", discount);
                }
              }}
              step="0.01"
              minLength={1}
              maxLength={5}
              style={{
                fontSize: "16px",
                color: netural400,
              }}
            />
          </Form.Group>
        </Fragment>
      </Col>
      <Col lg={2} md={2} sm={2} xs={2}>
        <Fragment>
          <Form.Group
            controlId="validationCustom01"
            style={{ color: "#A6A6A6", width: "80%" }}
          >
            <Form.Control
              required
              type="text"
              inputMode="numeric"
              placeholder="Quantity"
              style={{ borderRadius: "12px" }}
              value={quantity}
              onChange={(event) => {
                const newValue = event.target.value;
                const regex = /^[0-9\b]+$/;
                if (
                  (newValue === "" || regex.test(newValue)) &&
                  newValue.length < 7
                ) {
                  updateValue(index, "quantity", newValue);
                } else {
                  updateValue(index, "quantity", quantity);

                }
              }}
            />
          </Form.Group>
        </Fragment>
      </Col>
      <Col lg={2} md={2} sm={2} xs={2}>
        <Form.Group controlId="validationCustom01" style={{ color: "#A6A6A6" }}>
          <Form.Select
            aria-label="Default select example"
            style={{ borderRadius: "12px", width: "80%" }}
            value={
              isEdit ? variantCombination.quantityLimitation : quantityLimit
            }
            onChange={(event) => {
              if (isEdit) {
                updateValue(
                  index,
                  "quantityLimitation",
                  parseInt(event.target.value)
                );
              } else {
                updateValue(
                  index,
                  "quantityLimit",
                  parseInt(event.target.value)
                );
              }
            }}
          >
            {QUANTITY_RESTRICTION_DROPDOWN_OPTIONS.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Col>
      <Col lg={2} md={2} sm={2} xs={2}>
        <Form.Group
          controlId="validationCustom01"
          style={{ color: "#A6A6A6", width: "80%" }}
        >
          <Form.Control
            required
            type="text"
            placeholder="SKU"
            style={{ borderRadius: "12px" }}
            value={sku}
            onChange={(event) => {
              const newValue = event.target.value;

              // Use a regular expression to check if the entered value contains only digits and characters
              const regex = /^[a-zA-Z0-9]*$/;
              if (regex.test(newValue) || newValue === "") {
                updateValue(index, "sku", newValue);
              }
            }}
            step={"0.01"}
            minLength={3}
            maxLength={15}
          />
        </Form.Group>
      </Col>
    </div>
  );
};

export default ProductCombinationCard;
