import _ from "lodash";

export const variantList = [
  { id: 1, name: "Color" },
  { id: 2, name: "Size" },
  { id: 3, name: "Material" },
  { id: 4, name: "Flavour" },
];

export const monthlyMinimumCycleOptions = [1, 2, 3, 4, 5, 6];
export const weeklyMinimumCycleOptions = [
  1, 2, 3, 4, 5, 6, 7, 8, 12, 16, 20, 24,
];

export const business_approved =
  "Updates may take up to 7 days to be reflected on your account. Sales will continue under current info registered.";
export const business_pending =
  "Business not verified. Verify your business by submitting the information below.";
export const business_processing =
  "Verification of your business is in progress. You won’t be able to edit info at this time.";
export const business_reverifying =
  "Verification of your business is in progress. You won’t be able to edit info at this time.";
export const business_rejected = "Rejection reason: ";

export const returnPaginationRange = (totalPage, page, siblings, limit) => {
  let totalPageNoInArray = 5 + siblings;
  if (totalPageNoInArray >= totalPage) {
    return _.range(1, totalPage + 1);
  }
  let leftSiblingIndex = Math.max(page - siblings, 1);
  let rightSiblingIndex = Math.min(page + siblings, totalPage);

  let showLeftDots = leftSiblingIndex > 2;
  let showRightDots = rightSiblingIndex < totalPage - 2;

  if (!showLeftDots && showRightDots) {
    let leftItemCount = 3 + 2 * siblings;
    let leftRange = _.range(1, leftItemCount + 1);

    return [...leftRange, "...", totalPage];
  } else if (showLeftDots && !showRightDots) {
    let rightItemCount = 3 + 2 * siblings;
    let rightRange = _.range(totalPage - rightItemCount + 1, totalPage + 1);

    return [1, "...", ...rightRange];
  } else {
    let middleRange = _.range(leftSiblingIndex, rightSiblingIndex + 1);

    return [1, "...", ...middleRange, "...", totalPage];
  }
};

export const QUANTITY_RESTRICTION_DROPDOWN_OPTIONS = [
    { value: "0", label: "Select any one if required." },
    { value: "1", label: "One Unit" },
    { value: "2", label: "Two Units" },
    { value: "3", label: "Three Units" },
    { value: "4", label: "Four Units" },
    { value: "5", label: "Five Units" },
];
export const SERVICE_RESTRICTION_DROPDOWN_OPTIONS = [
  { value: "0", label: "Select any one if required." },
  { value: "1", label: "Once" },
  { value: "2", label: "Twice" },
  { value: "3", label: "Three times" },
  { value: "4", label: "Four times" },
  { value: "5", label: "Five times" },
];
